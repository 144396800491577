import { ROUTE_REGEX } from 'public/src/pre_requests/modules/productList/constant.js'

const FEEDBACK_REC_INFO_KEY = 'FEEDBACK_REC_INFO'
const FEEDBACK_REC_DRAWER_KEY = 'FEEDBACK_REC_DRAWER'
const FEEDBACK_REC_DATA_KEY = 'FEEDBACK_REC_DATA_KEY'
let FASHION_STORE_MARK = false
let TREND_CONTENT_MARK = false
// const FEEDBACK_REC_USE_CATID_KEY = 'FEEDBACK_REC_USE_CATID_KEY'

// wiki: pageId=1394253488 新增, 信息流入口 点后推商品用
export const setClickAndRecommend = (goods_id, haveRecommendGood = false) => {
  let data = {
    haveRecommendGood,
    ...getClickAndRecommend(),
    goodsId: goods_id,
  }
  sessionStorage && sessionStorage.setItem('CLICK_REC_INFO', JSON.stringify(data))
}
export const getClickAndRecommend = () => {
  if(!sessionStorage) {
    return
  }
  let data = JSON.parse(sessionStorage.getItem('CLICK_REC_INFO')) || null
  return data
}

// wiki: pageId=1394253488 新增, 信息流入口和信息流落地页曝光的商品
export const setListExposeGoodsSesstion = (storageName = 'LIST_EXPOSE_GOODS', goodsId) => {
  if(!sessionStorage || !sessionStorage?.getItem || !sessionStorage?.setItem || !storageName || !goodsId) {
    return
  }

  let useGoodsId = sessionStorage.getItem(storageName) || ''
  let useGoodsIdArr = [...new Set([...useGoodsId?.split(','), ...goodsId.split(',')]?.filter(item => item))]?.splice(-120) // 最多120条截取

  sessionStorage.setItem(storageName, useGoodsIdArr.join(','))
}
export const getListExposeGoodsSesstion = (storageName = 'LIST_EXPOSE_GOODS') => {
  if(!sessionStorage || !storageName || !sessionStorage?.getItem) {
    return
  }
  let goodsIdStr = sessionStorage.getItem(storageName) || ''
  return goodsIdStr
}
export const clearListExposeGoodsSesstion = (storageName = 'LIST_EXPOSE_GOODS') => {
  if(!sessionStorage || !storageName || !sessionStorage?.clear) {
    return
  }
  sessionStorage.removeItem(storageName)
}


// wiki: pageId=1370953064 补充主动清除store场景
export const clearCateRecCompSession = () => {
  const needDeleteValue = Object.keys(sessionStorage)?.filter((key)=>key?.includes('FEEDBACK_REC_DATA_CACHE_CATE') || key?.includes('FEEDBACK_REC_DATA_CACHE_ITEMSEARCH')) || []
  needDeleteValue?.length && needDeleteValue?.forEach(key=> {
    sessionStorage.removeItem(key) // 存之前先清掉
  })
}

// 缓存
/**
 * Description
 * @param {any} type='' 反馈弹窗的类型
 * @param {any} keys='' 用于拼接的sessionStorage key
 * @param {any} data='' 缓存的数据
 * @returns {any}
 */
export const setRecommendPopDataCache = (type = '', keys = '', data = '') => {
  const keyTable = {
    'realtime_feedback': 'FEEDBACK_REC_DATA_CACHE_REALTIME',
    'cate_feedback': 'FEEDBACK_REC_DATA_CACHE_CATE', // 这个存的是类目推荐中已经展示过的类目Id信息
    'hotRanking_feedback': 'FEEDBACK_REC_DATA_CACHE_RANKING',
    'cate_goods_feedback': 'FEEDBACK_REC_DATA_CACHE_CATE_GOODS', // 这个存的是类目推荐商品模式中弹窗的内的商品数据
    'itemSearch_feedback': 'FEEDBACK_REC_DATA_CACHE_ITEMSEARCH' // 搜索词商品模式
  }

  // 类目推荐缓存的是浏览过的类目ID
  if(type === 'cate_feedback') {
    sessionStorage.setItem(`${keyTable[type]}-${keys}`, JSON.stringify(data))
  }

  // 点后推搜索词商品&&卡片类型，缓存商品数据+推荐词数据 || 缓存推荐词数据
  if(type === 'itemSearch_feedback') {
    // 针对itemSearch_feedback data类型值为 {cardInfo: {xxx}, goodList: [...]}
    const needDeleteValue = Object.keys(sessionStorage)?.filter((key)=>key.includes(keyTable[type])) || []
    needDeleteValue?.length && needDeleteValue?.forEach(key=> {
      sessionStorage.removeItem(key) // 存之前先清掉
    })
    sessionStorage.setItem(`${keyTable[type]}-${keys}`, JSON.stringify(data))
  }

  // 实时反馈和榜单反馈 缓存的是弹窗的数据
  if(type === 'realtime_feedback' || type === 'hotRanking_feedback' || type === 'cate_goods_feedback') {
    const needDeleteValue = Object.keys(sessionStorage)?.filter((key)=>key.includes(keyTable[type])) || []
    needDeleteValue?.length && needDeleteValue?.forEach(key=> {
      sessionStorage.removeItem(key) // 存之前先清掉
    })
    sessionStorage.setItem(`${keyTable[type]}-${keys}`, JSON.stringify(data))
  }

}
export const getRecommendPopDataCache = (type = '', keys = '') => {
  const keyTable = {
    'realtime_feedback': 'FEEDBACK_REC_DATA_CACHE_REALTIME',
    'cate_feedback': 'FEEDBACK_REC_DATA_CACHE_CATE',
    'hotRanking_feedback': 'FEEDBACK_REC_DATA_CACHE_RANKING',
    'cate_goods_feedback': 'FEEDBACK_REC_DATA_CACHE_CATE_GOODS',
    'itemSearch_feedback': 'FEEDBACK_REC_DATA_CACHE_ITEMSEARCH' // 搜索词商品模式
  }
  let data = null

  if(type === 'cate_feedback') {
    data = JSON.parse(sessionStorage.getItem(`${keyTable[type]}-${keys}`)) || null
  }

  if(type === 'realtime_feedback' || type === 'hotRanking_feedback' || type === 'cate_goods_feedback') {
    data = JSON.parse(sessionStorage.getItem(`${keyTable[type]}-${keys}`)) || null
  }

  if(type === 'itemSearch_feedback') {
    data = JSON.parse(sessionStorage.getItem(`${keyTable[type]}-${keys}`)) || null
  }
  return data
}



// wiki: pageId=1186400824 增加，缓存反馈弹窗里面的数据
export const getFeedbackRecDataInSession = (goodsId) => {
  let data = JSON.parse(sessionStorage.getItem(`${FEEDBACK_REC_DATA_KEY}-${goodsId}`)) || null
  return data
}
export const setFeedbackRecDataInSession = (goodsId, data = {}) => {
  const needDeleteValue = Object.keys(sessionStorage)?.filter((key)=>key.includes('FEEDBACK_REC_DATA_KEY')) || []
  needDeleteValue?.length && needDeleteValue?.forEach(key=> {
    sessionStorage.removeItem(key) // 存之前先清掉
  })
  sessionStorage.setItem(`${FEEDBACK_REC_DATA_KEY}-${goodsId}`, JSON.stringify(data))
}


export const getFeedbackRecInSession = () => {
  const feedbackRec = JSON.parse(sessionStorage.getItem(FEEDBACK_REC_INFO_KEY)) || {}
  return feedbackRec
}

/**
 * @param {Object} feedbackRec
{
  goodsId: "",
  ymal: '', // ymal曝光的前20个goodsId
  similar: '', // similar曝光的前20个goodsId
  addWishStatus: 0 || 1,
  addBagStatus: 0 || 1
}
 */
export const setFeedbackRecInSession = (feedbackRec = {}) => {
  sessionStorage.setItem(FEEDBACK_REC_INFO_KEY, JSON.stringify(feedbackRec))
}


export const clearFeedbackRecInSession = () => {
  sessionStorage.removeItem(FEEDBACK_REC_INFO_KEY)
}

export const getFeedbackRecDrawerInSession = () => {
  const config = sessionStorage.getItem(FEEDBACK_REC_DRAWER_KEY)

  return config ? JSON.parse(config) : {}
}

/**
 * @param {Object} params
{
  visible: true || false,是否展示推荐半屏弹窗
}
 */
export const setFeedbackRecDrawerInSession = (params = {}) => {
  sessionStorage.setItem(FEEDBACK_REC_DRAWER_KEY, JSON.stringify({
    ...getFeedbackRecDrawerInSession(),
    ...params
  }))
}

const productDetailRegx = /(?:\/shell)?\/.+-p-(\d+)(-cat-(\d+))?\.html/ // 商详url正则
export const checkIsBackFromDetail = (path) => {
  return window.appRouteExtData.direction == -1 && (productDetailRegx.test(path) || path === 'page_goods_detail')
}
export const setFeedbackRecInfo = ({ productListInstance, path }) => {
  // 针对类目推荐词，非商详回来，非当前页刷新。清理上一次列表页中的缓存
  // if(!productDetailRegx.test(path) && path !== 'page_goods_detail' && path !== '/') {
  //   sessionStorage.removeItem(`${FEEDBACK_REC_USE_CATID_KEY}`)
  // }
  if(!productDetailRegx.test(path) && path !== 'page_goods_detail') {
    clearCateRecCompSession()
  }

  if (checkIsBackFromDetail(path)) {
    productListInstance?.showFeedbackRecPopup()
    // productListInstance?.showSearchRecMethod()
    productListInstance?.updatePopData() // 去重商详中的推荐展示数据 逻辑

    return true
  }
  // 增加榜单落地页回来
  if (window.appRouteExtData.direction == -1 && path?.includes('/ranking_list')) {
    productListInstance?.showFeedbackRecPopup()
  }
}

// 需要使用骨架屏loading的页面来源
const useSkeletonLoadingPageFrom = ['page_category']

/**
 * @description 是否使用骨架屏loading
 * @param {String} pageFrom 页面来源
 * @returns {Boolean} 是否使用骨架屏loading
 */
export const isUseSkeletonLoading = (pageFrom) => {
  if (!isShein()) return false
  return useSkeletonLoadingPageFrom.includes(pageFrom)
}


/**
 * @description 是否是重置请求类型
 * @param {AbortController} abortionInstance 中断请求实例
 */
export const isResetRequestType = (abortionInstance = {}) => {
  const signal = abortionInstance?.signal
  return signal?.reason === 'picTopNav2QueryAbortFetch'
}

/**
 * @description 是否是shein
 * @returns {Boolean} 是否是shein
 */
export const isShein = () => {
  return typeof window !== 'undefined' && window.gbCommonInfo?.WEB_CLIENT === 'shein'
}

const changeFlowMap = {}
// const setChangeFlowMap = (index, goods, flowMap) => {
//   for(let i = index + 1; i < goods.length; i++) {
//     if (changeFlowMap[i]?.originIndex === index) break // 这里会重复进，过滤重复赋值
//     const item = goods[i]
//     if (item.type !== 'recommend-for-you') {
//       if (!changeFlowMap[i]) { // 首次赋值
//         changeFlowMap[i] = {
//           ...flowMap[index],
//           originIndex: index
//         }
//       } else { // 同一个位置，不同内容体
//         const maxIndex = Math.max(...(Object.keys(changeFlowMap) || []))
//         changeFlowMap[maxIndex + 1] = {
//           ...flowMap[index],
//           originIndex: index
//         }
//       }
//       break
//     }

//     // 到最后以后还不是非点后推商品，直接插入
//     if (i === goods.length - 1) {
//       changeFlowMap[i + 1] = {
//         ...flowMap[index],
//         originIndex: index
//       }
//     }
//   }
// }
export const getWaterItems = ({ goods, flowMap, useFrom, noMoreData, flowSurveyInfo, showSurveyComponent, fashionStorePosition, trendContentPosition }) => {
  const waterItems = []
  if (useFrom === 'store') {
    const waterItems = [...(goods || [])]
    Object.keys(flowMap || {}).forEach((key) => {
      const data = flowMap[key]
      // 调查问卷组件特殊插坑逻辑
      if(data?.results?.[0]?.compTag === 'flowSurveyQuestion') {
        if (showSurveyComponent && waterItems.length >= Number(key)) {
          waterItems.splice(Number(key), 0, data)
        }
      } else if (data?.results?.length && waterItems.length >= Number(key)) {
        waterItems.splice(Number(key), 0, data)
      }
    })
    return waterItems
  }
  let recommendForYouGoodsSum = 0
  // goods包含点后推商品，插入末尾
  ;(goods || []).forEach((goodsItem, index) => {
    if (goodsItem.type === 'recommend-for-you') {
      recommendForYouGoodsSum += 1
    } else {
      goodsItem.originIndex = index - recommendForYouGoodsSum
    }
    // 调查问卷组件特殊插坑逻辑
    if(flowMap[index]?.results?.[0]?.compTag === 'flowSurveyQuestion') {
      if (showSurveyComponent) {
        waterItems.push(flowMap[index])
      }
    } else if(flowMap[index]) {
      const curIsRecommendForYouProduct = goodsItem.type === 'recommend-for-you'
      if (curIsRecommendForYouProduct) {
        waterItems.push(flowMap[index])
        // setChangeFlowMap(index, goods, flowMap)
        // waterItems.push(flowMap[index] || changeFlowMap[index])
        // index++
        // return
        //  else {
        //   if (!changeFlowMap[index + 1]) {
        //     changeFlowMap[index + 1] = flowMap[index]
        //   }
        // }
      } else {
        waterItems.push(flowMap[index])
      }
      // waterItems.push(flowMap[index])
    } else if (changeFlowMap[index]) {
      waterItems.push(changeFlowMap[index])
    }

    // 是否是时尚店铺内容体
    if (fashionStorePosition >= 0) {
      goodsItem.isFashionStore = hitFashionStoreAbt(goodsItem, waterItems.length, fashionStorePosition)
    }
    // 是否是趋势内容体
    if (trendContentPosition >= 0) {
      goodsItem.isTrendContent = hitTrendContentAbt(goodsItem,  waterItems.length, trendContentPosition)
    }
    // goodsItem.goods_id && waterItems.push(goodsItem)
    waterItems.push(goodsItem)
  })
  if (noMoreData) {
    // 调查问卷组件特殊插坑逻辑
    if (+flowSurveyInfo?.showPosition > goods.length && showSurveyComponent) {
      const data = {
        isFlowData: true,
        results: [{
          index: +flowSurveyInfo?.showPosition - 1,
          compTag: 'flowSurveyQuestion',
          props: {
            surveyInfo: flowSurveyInfo,
          },
        }]
      }
      waterItems.push(data)
    }
  }
  return waterItems
}

const hitFashionStoreAbt = (item, index, position) => {
  const itemIndex = index + 1
  // 从1开始render，说明整个列表已经重新render了，就直接重新计算时尚店铺是否出现过
  if (itemIndex === 1) {
    FASHION_STORE_MARK = false
  }
  if(FASHION_STORE_MARK) return false

  // 一键购不允许变成时尚店铺
  const isModStore = item.storeInfo && item.storeInfo.isModStore && item.storeInfo.title && !item.oneClickPay
  if ((itemIndex > position) && isModStore) {
    FASHION_STORE_MARK = true
    return true
  } else {
    return false
  }
}

const hitTrendContentAbt = (item, index, position) => {
  const itemIndex = index + 1
  // 从1开始render，说明整个列表已经重新render了，就直接重新计算趋势内容体是否出现过
  if (itemIndex === 1) {
    TREND_CONTENT_MARK = false
  }
  if(TREND_CONTENT_MARK) return false

  // 一键购不允许变成趋势词内容体
  const isTrendContent = item.deliveryInfo && item.deliveryInfo.trend_word_id && !item.oneClickPay

  if ((itemIndex > position) && isTrendContent) {
    TREND_CONTENT_MARK = true
    return true
  } else {
    return false
  }
}

export const TRIGGER_EVENT_MAP = {
  'AddCarFailFavSuccess': '1',
  'AddCarFailFavFail': '2',
  'AddCarSuccessFavSuccess': '3',
  'AddCarSuccessFavFail': '4',
  'NotJudged': '5',
}

/**
 * @description abt解析给到推荐的ruleId
 * @param {*} listAbtResult abtList
 * @param {*} triggerEvent 触发事件
 * @param {*} recSwitch 是否满足指定的场景
 * @param {*} useAppointPostkey 指定的场景postkey
 * @returns
 */
export const feedbackAbtToRuleId = (listAbtResult, triggerEvent, useAppointPostkey) => {
  const ruleId = {}
  const index = TRIGGER_EVENT_MAP[triggerEvent]
  let abt = listAbtResult?.RealtimeFeedbackJson?.p || {}
  if(useAppointPostkey) {
    abt = listAbtResult?.[useAppointPostkey]?.p || {}
  }
  Object.keys(abt).forEach((key) => {
    const arr = abt[key].split('&')
    if (arr.length <= 1) {
      ruleId[key] = abt[key]
    } else {
      arr.forEach(str => {
        const [k, v] = str.split('=')
        if (k === index) {
          ruleId[key] = v
        }
      })
    }
  })
  return ruleId
}

// 页面标识映射pageName -> ccc pageType
export const PAGE_NAME_MAP_PAGE_KEY = {
  page_real_class: 'page_real_class',
  page_select_class: 'page_select_class',
  page_daily_new: 'daily_new',
  page_selling_point: 'page_selling_point',
  page_goods_group: 'shein_picks',
  page_picked: 'page_picked',
  page_search: 'page_search'
}


// 分辨当前的页面类型
// * 维护不同类型的搜索页的差异
// * @param {Object} options - 传catInfo即可。
// * @param {string} options.pageName - 当前页面的名称。
// * @param {string} options.search_type - 要执行的搜索类型。
// * @param {string} options.type - 要执行的页面name
// * @returns {{
// isSelectListPage, // 判断是否为选品列表
// isEntityListPage, // 判断是否为真实列表
// isSearch, // 判断是否为搜索结果列表
// isStoreSearch, // 判断是否为店铺搜索
// isBrandSearch, // 判断是否为专题搜索
// isPickInfoPage // 判断是否为信息流落地页
// * }}
export function getListPageInfo({ search_type: type, type: pageType, pageName } = {}) {
  const SEARCH_TYPE_ENUMS = {
    store: 'store',
    brand: 'brand',
    all: 'all',
    selection: 'selection',
    entity: 'entity',
    search: 'search',
    pickInfo: 'picked',
    picks: 'picks',
    trend: 'pageTrend'
  }

  const isStoreSearch = type === SEARCH_TYPE_ENUMS.store // 判断是否为店铺搜索
  const isBrandSearch = type === SEARCH_TYPE_ENUMS.brand // 判断是否为专题搜索
  const isTrendSearch = type === SEARCH_TYPE_ENUMS.trend // 判断是否为趋势搜索
  const isSearch = pageType === SEARCH_TYPE_ENUMS.search && !isStoreSearch && !isTrendSearch && !isBrandSearch // 判断是否为搜索结果列表
  const isSelectListPage = pageType === SEARCH_TYPE_ENUMS.selection // 判断是否为选品列表
  const isOnlyListSelectPage = pageType === SEARCH_TYPE_ENUMS.selection && !isStoreSearch // 判断是否仅为列表业务的选品列表，区分店铺选品列表
  const isEntityListPage = pageType === SEARCH_TYPE_ENUMS.entity // 判断是否为真实列表
  const isStorePage = pageType === SEARCH_TYPE_ENUMS.store && !type // 判断是否为店铺列表
  const isPickInfoPage = pageType === SEARCH_TYPE_ENUMS.pickInfo || pageType === 'pick' || pageName === 'page_picked' // 判断是否为信息流落地页

  const isSheinPicks = pageType === SEARCH_TYPE_ENUMS.picks // shein-picks 列表
  const isAllSearchType = pageType === SEARCH_TYPE_ENUMS.search // 判断是否为搜索页，不区分类型的搜索
  // 当前搜索结果页类型有店铺搜索，趋势搜索，品牌搜索，列表搜索。注意区分！！！

  return {
    isSelectListPage, // 判断是否为选品列表
    isOnlyListSelectPage, // 判断是否仅为列表业务的选品列表，区分店铺选品列表
    isEntityListPage, // 判断是否为真实列表
    isSearch, // 判断是否为搜索结果列表
    isStoreSearch, // 判断是否为店铺搜索
    isBrandSearch, // 判断是否为专题搜索
    isStorePage, // 判断是否为店铺列表
    isPickInfoPage, // 判断是否为信息流落地页
    isSheinPicks,
    isAllSearchType, // 判断是否为搜索页，不区分类型的搜索
  }
}

/**
 * 根据 catInfo 获取 cat_id
 */
export function getCatId(catInfo = {}, defaultCatId = false) {
  const { pageName, entity_id, select_id, cat_id } = catInfo
  const isRealPage = pageName === 'page_real_class'
  const isSelectPage = pageName === 'page_select_class'
  if (isRealPage) {
    return entity_id
  }
  if (isSelectPage) {
    return select_id
  }
  return defaultCatId ? cat_id : ''
}

// 通过path获取页面类型
export const getPageTypeByRoute = (path) => {
  if (path.match(ROUTE_REGEX.search)) return 'search'
}

export const isServer = () => {
  return typeof window === 'undefined'
}
